import React, { useState, useEffect } from 'react';
import DataServices from '../api/services';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import Logo from '../logo.svg';

const CreateAccount = () => {
    const [accountCreated, setAccountCreated] = useState(false);
    const [message, setMessage] = useState('');
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        staff: {
            phone_no: '',
            address: '',
            ip_address: '',
            role: 'Staff' // Assuming 'null' is the default value
        }
    });
    const [errors, setErrors] = useState({});
    const [formVisible, setFormVisible] = useState(true);

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        // Email validation
        if (!formData.email) {
            formIsValid = false;
            errors["email"] = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            formIsValid = false;
            errors["email"] = "Email is invalid.";
        }

        // Username validation
        if (!formData.username) {
            formIsValid = false;
            errors["username"] = "Username is required.";
        } else if (formData.username.length < 4) {
            formIsValid = false;
            errors["username"] = "Username must be at least 4 characters long.";
        }

        // Password validation
        if (!formData.password) {
            formIsValid = false;
            errors["password"] = "Password is required.";
        } else if (formData.password.length < 6) {
            formIsValid = false;
            errors["password"] = "Password must be at least 6 characters long.";
        }

        setErrors(errors);
        return formIsValid;
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name.includes('.')) {
            const [key, nestedKey] = name.split('.');
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: {
                    ...prevFormData[key],
                    [nestedKey]: value
                }
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return;
        }

        try {
            const emailCheckResult = await DataServices.checkEmailExist(formData.email);
            if (emailCheckResult.email_exists === true) {
                setAccountCreated(false);
                setMessage('Account already registered. Please log in or use the forgot password feature if necessary.');
                return;
            }

            await DataServices.createUser(formData);
            setFormVisible(false);
            setAccountCreated(true);
            setMessage('Account created successfully. Please check your email to activate your account.');
        } catch (error) {
            setAccountCreated(false);
            setMessage('Failed to create account. Please try again.');
        }
    };



    return (
        <div className="create-account">
            <div className="create-account__wrapper">
                <Row className="justify-content-center">
                    <Col md={7} lg={5} xl={4}>
                        <img src={Logo} className="mb-5 login__logo" alt="Logo" width="250px" />
                        <h2 className="mb-4">Create Account</h2>
                        {message && (
                            <div className={`alert ${accountCreated ? 'alert-success' : 'alert-danger'}`}>
                                {message}
                            </div>
                        )}
                        {formVisible && ( // Conditional rendering based on formVisible state
                            <Form onSubmit={handleSubmit}>

                                <Form.Group className="mb-3">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.email}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.email}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.username}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.username}
                                    </Form.Control.Feedback>
                                </Form.Group>


                                <Form.Group className="mb-4">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        name="password"
                                        value={formData.password}
                                        onChange={handleInputChange}
                                        isInvalid={!!errors.password}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.password}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Button variant="primary" type="submit" className="w-100 mt-2">
                                    Create account
                                </Button>
                            </Form>
                        )}
                    </Col>
                </Row>
            </div>
        </div>

    );
};

export default CreateAccount;