import React, { useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import Logo from '../logo.svg';
import DataServices from '../api/services';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handlePasswordResetSubmit = async (event) => {
        event.preventDefault();
        setMessage("");
        setErrorMessage("");

        try {
            await DataServices.resetPassword(email);
            setMessage("If an account with that email exists, we have sent a password reset link.");
        } catch (error) {
            console.error(error);
            setErrorMessage("Failed to send password reset link. Please try again later.");
        }
    };

    return (
        <Container className="d-flex align-items-center justify-content-center forgot-password-container">
            <Form onSubmit={handlePasswordResetSubmit} className="w-100 forgot-password-form">
                <Row className="mb-4 text-center custom-row">
                    <Col md={10} className="text-md-start text-center">
                    <img src={Logo} alt="Logo" className="mb-5 login__logo" width="300px" />
                        <h3 className="mb-3">Password Reset</h3>
                        <p className="mb-2">Enter your email address to reset your password.</p>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <Form.Group className="mb-3" >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col className="d-flex justify-content-center">
                        <Button variant="primary" size="sm" type="submit">Send Reset Link</Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {message && <div className="alert alert-success">{message}</div>}
                        {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                    </Col>
                </Row>
            </Form>
        </Container>
    );
}

export default ForgotPassword;
