import { useEffect, useState, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import DataServices from '../api/services';

import Layout from "../components/layout";
import FormEditBuild from '../components/form-edit-build';
import FormSettings from "../components/form-settings";
import FormPublish from "../components/form-publish";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import 'react-form-builder2/dist/app.css';

const EditForm = (props) => {
    useLayoutEffect(() => {
        window.__isReactDndBackendSetUp = false;
    }, [])

    let params = useParams();
    const navigate = useNavigate();
    const { authTokens } = useAuth();
    const { isNavOpen, setIsNavOpen } = props;
    const formId = params.id;

    const [currentTab, setCurrentTab] = useState(0);
    const [formInfo, setFormInfo] = useState([]);
    const [formData, setFormData] = useState([]);
    const [fields, setFields] = useState([]);
    const [savedFields, setSavedFields] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const [errors, setErrors] = useState({
        name: "",
        reciepient_blob: "",
    });

    useEffect(() => {
        if (formId) {
            DataServices.getFormById(authTokens, formId).then(res => {
                if (res.id) {
                    setFormInfo({
                        name: res.name,
                        status: res.status,
                        disabled_msg: res.disabled_msg,
                        max_submission: res.max_submission,
                        reciepient_blob: res.reciepient_blob
                    });
                    setFormData(res);
                    let getFields = [];
                    let getSavedFields = [];
                    res.form_fields.map((r) => {
                        getFields.push(JSON.parse(r.data));
                        getSavedFields.push({
                            id: r.id,
                            data: JSON.parse(r.data)
                        })
                    })
                    setFields(getFields);
                    setSavedFields(getSavedFields);
                } else {
                    navigate('/')
                }
                setIsLoaded(true);
            })
                .catch(err => console.log(err));
        }
    }, [authTokens, formId])

    const isMultipleEmailsValid = (emailInput) => {
        const emails = emailInput.split(",");
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let i = 0; i < emails.length; i++) {
            emails[i] = emails[i].trim();
            if (emails[i] === "" || !regex.test(emails[i])) {
                return false;
            }
        }
        return true;
    }

    const validateAll = () => {
        const { name, reciepient_blob } = formData;
        let isValid = true;
        if (!reciepient_blob) {
            setErrors({
                ...errors,
                reciepient_blob: 'This field is required.',
            });
            isValid = false
        } else if (!isMultipleEmailsValid(reciepient_blob)) {
            setErrors({
                ...errors,
                reciepient_blob: 'Invalid email.',
            });
            isValid = false
        }

        if (!name) {
            setErrors({
                ...errors,
                name: 'This field is required.',
            });
            isValid = false
        }

        return isValid;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setFormInfo({
            ...formInfo,
            [name]: (name === 'max_submission' && !value) ? 5000 : value,
        });
    };

    const handleNext = (event) => {
        setCurrentTab((prev) => prev + 1);
    }

    const handleTab = (id) => {
        setCurrentTab(id);
    }

    const handleSubmit = () => {
        const isValid = validateAll();
        if (isValid) {
            DataServices.updateForm(authTokens, formId, formInfo).then(resp => {
                setIsSuccess(true);
                setTimeout(() => {
                    setIsSuccess(false);
                }, 2000);
            })
                .catch(err => console.log(err));
        }
    }

    const handleBack = (event) => {
        if (currentTab === 0) {
            navigate(-1);
        } else {
            setCurrentTab((prev) => prev - 1)
        }
    }

    if (isLoaded) {
        return (
            <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
                <div className="create">
                    <Tab.Container activeKey={currentTab}>
                        <Nav className="create__nav">
                            <Nav.Item className="me-auto">
                                <Nav.Link className="back-button" disabled={currentTab === 0} onClick={handleBack}><FontAwesomeIcon icon={faArrowLeftLong} /> Back</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={0} title="Build" onClick={() => handleTab(0)}>Build</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={1} title="Settings" onClick={() => handleTab(1)}>Settings</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey={2} title="Publish" onClick={() => handleTab(2)}>Publish</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="ms-auto">
                                <Nav.Link className="back-button" disabled={currentTab === 2} onClick={handleNext}>Next <FontAwesomeIcon icon={faArrowRightLong} /></Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content>
                            <Tab.Pane eventKey={0}>
                                <FormEditBuild className="create__build" formData={formData} setFormData={setFormData} savedFields={savedFields} setSavedFields={setSavedFields} fields={fields} setFields={setFields} formId={formId} token={authTokens} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={1}>
                                {isSuccess &&
                                    <div className="alert alert-success mb-3">Your form settings has been successfully updated!</div>
                                }
                                <FormSettings formData={formData} errors={errors} handleChange={handleChange} setErrors={setErrors} isEdit={true} handleSubmit={handleSubmit} />
                            </Tab.Pane>
                            <Tab.Pane eventKey={2}>
                                <FormPublish embeddedCode={formData.embed_code} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Layout>
        );
    }
}

export default EditForm;