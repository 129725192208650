import { useEffect, useState, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import DataServices from '../api/services';
import Layout from "../components/layout";
import PaginationComponent from "../components/pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderClosed } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Modal, Button } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Home = (props) => {
  const {isNavOpen, setIsNavOpen} = props;
  const { authTokens, user } = useAuth();
  const [folders, setFolders] = useState([]);
  const [searchParams] = useSearchParams();
  const [sortOption, setSortOption] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0); // Assuming API provides total item count
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const pageTopRef = useRef(null);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [showPermissionsModal, setShowPermissionsModal] = useState(false);
  const [users, setUsers] = useState([]);

  const isUpdated = searchParams.get('updated');
  useEffect(() => {
    // Function to fetch data
    const fetchData = async () => {
      try {
        const res = await DataServices.getFoldersbyUserID(authTokens, user.id, page, pageSize, searchQuery, sortOptionMap[sortOption]);
        if (res.count > 0) {
          setTotalItems(res.count);
          setFolders(res.results);
          // Set folders from API response
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (authTokens && user && user.id) {
      fetchData(); // Fetch data whenever dependencies change
    }
  }, [authTokens, user, isUpdated, page, pageSize, searchQuery, sortOption]); // Include sortOption as a dependency


  // Map frontend sort options to backend query parameters
  const sortOptionMap = {
    '1': 'lastupdated', // Change to your actual backend query parameter for sorting by last updated
    '2': 'created', // Change to your actual backend query parameter for sorting by date created
    '3': 'name',        // Change to your actual backend query parameter for sorting by name
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await DataServices.getUsers(authTokens, user);
        setUsers(res.results);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }

    };

    fetchUsers();
  }, []);



  const handleSortChange = (event) => {
    setSortOption(event.target.value);
    setPage(1);
  };


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); // Update the search query state
  };

  // Pagination Handlers
  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setPage(1);
  }

  const navigateToFolder = (folderId) => {
    navigate("/folder/" + folderId);
  };

  const handleDelete = (e, folderId) => {
    e.preventDefault();
    e.stopPropagation();
    const folder = folders.find(f => f.id === folderId);
    setSelectedFolder(folder);
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const confirmDelete = async () => {

    try {
      const response = await DataServices.deleteFolder(authTokens, selectedFolder.id);
      setFolders(prevFolders => prevFolders.filter(folder => folder.id !== selectedFolder.id));
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Failed to delete folder:', error);

    }
  };

  useEffect(() => {
    if (selectedFolder) {
      setSelectedUserIds(selectedFolder.permitted_users || []);
    }
  }, [selectedFolder]);


  const onUserPermissionChange = (userId, isChecked) => {
    setSelectedUserIds(prevSelectedUserIds => {
      if (isChecked && !prevSelectedUserIds.includes(userId)) {
        return [...prevSelectedUserIds, userId];
      } else if (!isChecked) {
        return prevSelectedUserIds.filter(id => id !== userId);
      } else {
        return prevSelectedUserIds;
      }

    });
  };

  const handleSavePermissions = async () => {
    try {
      const response = await DataServices.permissionAdmin(authTokens, selectedFolder.id, selectedUserIds);
      setShowPermissionsModal(false);
    } catch (error) {
      console.error("Failed to save permissions:", error);
    }
  };

  const openPermissionsModal = (e, folder) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedFolder(folder);
    setShowPermissionsModal(true);
  };


  return (
    <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
      <div className="main__wrapper" ref={pageTopRef}>
        <h4>All Folders</h4>
        <Row className="mt-4 justify-content-md-between">
          <Col md={6} xl={5} className="mb-4">
            <Form.Control
              type="search"
              name="search"
              placeholder="Search folder name"
              value={searchQuery} // Bind to the searchQuery state
              onChange={handleSearchChange} // Set the handler to update state
            />
          </Col>

          <Col md={6} className="mb-4">
            <div className="d-flex justify-content-md-end align-items-center gap-3">
              <span className="col-auto text-small">Sort by</span>
              <Form.Select className="w-auto select--sort form-select--green" value={sortOption} onChange={handleSortChange}>
                {/* <option value="">Select sort...</option> */}
                <option value="1">Last Updated</option>
                <option value="2">Date Created</option>
                <option value="3">Name</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
        <div className="bchu-list bchu-list--folders">
          {
            folders && folders.length > 0 ? (
              folders
                .filter(folder => folder.name.toLowerCase().includes(searchQuery.toLowerCase()))
                .map((folder, i) => (
                  <div key={i} className="bchu-list-item" onClick={() => navigateToFolder(folder.id)}>
                    <div className="bchu-list-item__icon"><FontAwesomeIcon icon={faFolderClosed} /></div>
                    <div className="bchu-list-item__info">
                      <h5>{folder.name}</h5>
                      <div className="bchu-list-item__info-meta">
                        {folder.number_forms} Forms | {folder.number_active_forms} Active
                        {folder.owner_details && folder.owner_details.username !== user.username
                          ? ` | Shared by: ${folder.owner_details.full_name || folder.owner_details.username}`
                          : ''}
                      </div>
                    </div>
                    <div className="folder-link bchu-list-item__actions" onClick={(e) => e.stopPropagation()}>
                      {user.role === 'admin' && user.id === folder.owner_details.id && (
                        <NavDropdown title="More" className="ellipsis">
                          <NavDropdown.Item onClick={(e) => openPermissionsModal(e, folder)}>Permission</NavDropdown.Item>
                          <NavDropdown.Item onClick={(e) => handleDelete(e, folder.id)}>Delete</NavDropdown.Item>
                        </NavDropdown>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <div className="no-folders-message my-5">You have no folder entry at the moment.</div>
            )
          }

        </div>

        <Modal show={showPermissionsModal} onHide={() => setShowPermissionsModal(false)} centered
          aria-labelledby="contained-modal-title-vcenter">
          <Modal.Header closeButton>
            <Modal.Title>{selectedFolder?.name} | Permissions</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {users && users.map(user => (
              <Form.Check key={user.id}>
                <Form.Check.Input
                  type="checkbox"
                  id={`user-permission-${user.id}`}
                  checked={selectedUserIds.includes(user.id)}
                  onChange={(e) => onUserPermissionChange(user.id, e.target.checked)} />
                <Form.Check.Label htmlFor={`user-permission-${user.id}`}>
                  {user.full_name || user.username}
                  <span className="user-role"> {user.role ? `(${user.role})` : ''}</span>
                </Form.Check.Label>
              </Form.Check>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={() => setShowPermissionsModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSavePermissions}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showDeleteModal}
          onHide={closeDeleteModal}
          centered
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Body className="p-4">
            <h6 className="mb-4">Are you sure you want to delete the folder "{selectedFolder?.name}" folder?</h6>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={closeDeleteModal}>Cancel</Button>
            <Button variant="primary" onClick={confirmDelete}>Delete</Button>
          </Modal.Footer>
        </Modal>

        <hr></hr>
        <Row>
          <Col md={6}>
            <div className="page-size-selection mb-3 d-flex align-items-center gap-2">
              <label htmlFor="pageSize">Items per page: </label>
              <select
                className="form-control w-auto py-2"
                name="pageSize"
                id="pageSize"
                value={pageSize}
                onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </Col>
          <Col md={6}>
            {totalItems > pageSize &&
              <PaginationComponent
                itemsCount={totalItems}
                itemsPerPage={pageSize}
                currentPage={page}
                setPage={setPage}
                pageTopRef={pageTopRef}
              />
            }
          </Col>
        </Row>
      </div>
    </Layout>
  );
}

export default Home;
