import { useEffect, useState, useLayoutEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataServices from '../api/services';

import Layout from "../components/layout";
import FormBuild from "../components/form-build";
import FormSettings from "../components/form-settings";
import FormPublish from "../components/form-publish";

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faArrowRightLong } from '@fortawesome/free-solid-svg-icons';

import 'react-form-builder2/dist/app.css';


const Create = (props) => {
    useLayoutEffect(() => {
        window.__isReactDndBackendSetUp = false;
    }, [])
    const { isNavOpen, setIsNavOpen } = props;
    const [searchParams] = useSearchParams();
    const { authTokens, user } = useAuth();
    const navigate = useNavigate();
    const folder = searchParams.get('folder');
    const [currentTab, setCurrentTab] = useState(0);
    const [errors, setErrors] = useState({
        name: "",
        reciepient_blob: "",
    });
    const [formData, setFormData] = useState({
        folder: folder,
        form_fields: [],
        name: "",
        status: "enabled",
        max_submission: 5000,
        reciepient_blob: "",
        disabled_msg: "",
        embed_code: "1",
        is_visible: true
    });
    const [embeddedCode, setEmbeddedCode] = useState("");
    const [isDraftSuccess, setIsDraftSuccess] = useState(false);

    useEffect(() => {
        if (!authTokens) return;

        if (!folder) {
            navigate('/')
        }

        setFormData({
            ...formData,
            folder: folder,
        });
    }, [authTokens, folder])

    const generateEmbedForm = (id) => {
        return `
          <!-- Begin BCHU Form -->
          <div id="bchu-form" data-id="${id}"></div>
          <script src="${process.env.REACT_APP_WEB_BASE_URL}/dist/bchu-form.js"></script>
          <!-- End BCHU Form -->
        `
    };

    const isMultipleEmailsValid = (emailInput) => {
        const emails = emailInput.split(",");
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let i = 0; i < emails.length; i++) {
            emails[i] = emails[i].trim();
            if (emails[i] === "" || !regex.test(emails[i])) {
                return false;
            }
        }
        return true;
    }

    const validateAll = () => {
        const { name, reciepient_blob } = formData;
        let isValid = true;
        if (!reciepient_blob) {
            setErrors({
                ...errors,
                reciepient_blob: 'This field is required.',
            });
            isValid = false
        } else if (!isMultipleEmailsValid(reciepient_blob)) {
            setErrors({
                ...errors,
                reciepient_blob: 'Invalid email.',
            });
            isValid = false
        }

        if (!name) {
            setErrors({
                ...errors,
                name: 'This field is required.',
            });
            isValid = false
        }

        return isValid;
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (event) => {
        const isValid = validateAll();
        if (isValid) {
            DataServices.createForm(authTokens, JSON.stringify(formData)).then(res => {
                console.log(res);
                if (res.id) {
                    console.log(res.id);
                    const code = generateEmbedForm(res.id);
                    setEmbeddedCode(code);
                    DataServices.updateForm(authTokens, res.id, { embed_code: code }).then(resp => {
                        console.log(resp);
                        if (resp) {
                            setFormData({
                                ...formData,
                                embed_code: code,
                            });
                            setCurrentTab(2);
                        }
                    })
                        .catch(err => console.log(err));
                }
            })
                .catch(err => console.log(err));
        }
    }

    const handleSaveAsDraft = (event) => {
        if (!formData['name']) {
            formData['name'] = "No Title (Draft)";
        }
        if (!formData['reciepient_blob']) {
            formData['reciepient_blob'] = user.email;
        }
        formData['status'] = 'disabled';
        formData['is_visible'] = false;
        DataServices.createForm(authTokens, JSON.stringify(formData)).then(res => {
            if (res.id) {
                const code = generateEmbedForm(res.id);
                setEmbeddedCode(code);
                DataServices.updateForm(authTokens, res.id, { embed_code: code }).then(resp => {
                    console.log(resp);
                    if (resp) {
                        setFormData({
                            ...formData,
                            embed_code: code,
                        });
                        setIsDraftSuccess(true);
                    }
                })
                    .catch(err => console.log(err));
            }
        })
            .catch(err => console.log(err));
    }

    const handleNext = (event) => {
        if (currentTab === 0) {
            setCurrentTab((prev) => prev + 1);
        } else if (currentTab === 1) {
            handleSubmit();
        }
    }

    const handleBack = (event) => {
        if (currentTab === 0) {
            navigate(-1);
        } else {
            setCurrentTab((prev) => prev - 1)
        }
    }

    const handleTab = (id) => {
        if (id === 2 && !embeddedCode) {
            handleSubmit();
            setCurrentTab(1);
        } else {
            setCurrentTab(id);
        }
    }

    return (
        <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
            <div className="create">
                <Tab.Container activeKey={currentTab}>
                    <Nav className="create__nav">
                        <Nav.Item className="me-auto">
                            <Nav.Link className="back-button" disabled={currentTab === 0} onClick={handleBack}><FontAwesomeIcon icon={faArrowLeftLong} /> Back</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={0} title="Build" onClick={() => handleTab(0)}>Build</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={1} title="Settings" onClick={() => handleTab(1)}>Settings</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey={2} title="Publish" onClick={() => handleTab(2)}>Publish</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className="ms-auto">
                            <Nav.Link className="back-button" disabled={currentTab === 2 || embeddedCode} onClick={handleNext}>Next <FontAwesomeIcon icon={faArrowRightLong} /></Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey={0}>
                            {isDraftSuccess &&
                                <div className="alert alert-success mb-3">Your form is successfully saved as Draft!</div>
                            }
                            <FormBuild className="create__build" formData={formData} setFormData={setFormData} context={window} handleSaveAsDraft={handleSaveAsDraft} isDraftSuccess={isDraftSuccess} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={1}>
                            <FormSettings formData={formData} errors={errors} handleChange={handleChange} setErrors={setErrors} />
                        </Tab.Pane>
                        <Tab.Pane eventKey={2}>
                            <FormPublish embeddedCode={embeddedCode} />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </Layout>
    );
}

export default Create;