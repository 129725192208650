import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import Form from 'react-bootstrap/Form';

const FormSettings = (props) => {
    const { formData, handleChange, errors, setErrors, isEdit, handleSubmit } = props;

    useEffect(() => {
        setErrors(errors)
    }, [errors])

    const isMultipleEmailsValid = (emailInput) => {
        const emails = emailInput.split(",");
        const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        for (let i = 0; i < emails.length; i++) {
            emails[i] = emails[i].trim();
            if (emails[i] == "" || !regex.test(emails[i])) {
                return false;
            }
        }
        return true;
    }
    const validateField = (e) => {
        const { name } = e.target
        const value = formData[name]
        let message = '';

        if (!value) {
            message = 'This field is required';
        } else if (name == 'reciepient_blob') {
            const isValid = isMultipleEmailsValid(value);
            if (!isValid) {
                message = 'Invalid email';
            }
        }
        setErrors({ ...errors, [name]: message })
    }
    return (
        <>
            {isEdit &&
                <div className="d-flex justify-content-end mb-4">
                    <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </div>
            }
            <div className="main__wrapper">
                <h5 className="fw-500 mb-4">Form Settings</h5>
                <Form.Group className="mb-4">
                    <Form.Label className="h5">Title</Form.Label>
                    <p>Enter a title for your form.</p>
                    <Form.Control type="text" name="name" className="form-control-xl-half" value={formData.name || ''} onBlur={validateField} onChange={handleChange} isInvalid={!!errors.name} />
                    <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
                </Form.Group>
                {/* <Form.Group className="mb-4">
                    <Form.Label className="h5">Form Status</Form.Label>
                    <p>Disable your form now, on a specific date, or when it reaches a certain number of submissions</p>
                    <Form.Control type="text" name="status" className="form-control-xl-half" onChange={handleChange} />
                </Form.Group> */}
                <Form.Group className="mb-4">
                    <Form.Label className="h5">Disabled Message</Form.Label>
                    <p>Enter a message when the form is disabled.</p>
                    <Form.Control type="text" placeholder="If left blank, disabled form will be hidden." name="disabled_msg" value={formData.disabled_msg} className="form-control-xl-half" onChange={handleChange} />
                </Form.Group>
                <Form.Group className="mb-4">
                    <Form.Label className="h5">Maximum no. of submissions</Form.Label>
                    <p>Enter a maximum number of submissions.</p>
                    <Form.Control type="number" name="max_submission" value={isEdit ? formData.max_submission : ''} className="form-control-xl-half" placeholder="If left blank, the default max submission is 5,000" onChange={handleChange} />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="h5">Recipient</Form.Label>
                    <p>Enter email addresses separated by a comma.</p>
                    <Form.Control type="text" name="reciepient_blob" className="form-control-xl-half" value={formData.reciepient_blob} onBlur={validateField} onChange={handleChange} isInvalid={!!errors.reciepient_blob} />
                    <Form.Control.Feedback type="invalid">{errors.reciepient_blob}</Form.Control.Feedback>
                </Form.Group>
            </div>
            {isEdit &&
                <div className="d-flex justify-content-end mt-4">
                    <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </div>
            }
        </>
    );
}

export default FormSettings;