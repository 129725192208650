import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { useState } from 'react';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './utils/PrivateRoute'

import Home from './pages/Home';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import Folder from './pages/Folder';
import Create from './pages/Create';
import AccountSettings from './pages/AccountSettings';
import EditForm from './pages/EditForm';
import Submissions from './pages/Submissions';
import ViewSubmission from './pages/ViewSubmission';
import Page404 from './pages/404';
import PasswordResetConfirm from './pages/PasswordResetComponent';
import CreateAccount from './pages/CreateAccount';
import WelcomePage from './pages/WelcomePage';
import RegistrationForm from './pages/RegistrationForm';


function App() {
  const [isNavOpen, setIsNavOpen] = useState(true);
  return (
    <div className="App">
        <Router>
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<PrivateRoute><Home isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/></PrivateRoute>} />
                    <Route path="/login" element={<Login />}/>
                    <Route path="/forgot-password" element={<ForgotPassword/>} />
                    <Route path="/create-account" element={<CreateAccount/>} />
                    <Route path="/activate/:uid/:token" element={<WelcomePage/>} />
                    <Route path="/registration" element={<PrivateRoute><RegistrationForm/></PrivateRoute>}/>
                    <Route path="/password-reset/:uid/:token" element={<PasswordResetConfirm/>} />
                    <Route path="/folder/:id" element={<PrivateRoute><Folder isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/></PrivateRoute>} />
                    <Route path="/create" element={<PrivateRoute><Create isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/></PrivateRoute>} />
                    <Route path="/account-settings" element={<PrivateRoute><AccountSettings isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/></PrivateRoute>} />
                    <Route path="/edit-form/:id" element={<PrivateRoute><EditForm isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/></PrivateRoute>} />
                    <Route path="/submissions/:id" element={<PrivateRoute><Submissions isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/></PrivateRoute>} />
                    <Route path="/submissions/view/:id" element={<PrivateRoute><ViewSubmission isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}/></PrivateRoute>} />
                    <Route path="*" element={<Page404 isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />} />
                </Routes>
            </AuthProvider>
        </Router>
    </div>
  );
}

export default App;