import React, { useEffect, useState, useLayoutEffect } from 'react';
import { ReactFormBuilder, ElementStore } from 'react-form-builder2';
import DemoBar from './demobar';
import DataServices from "../api/services";
import { toolbarItems } from './toolbarItems';

const FormEditBuild = (props) => {
    const { savedFields, setSavedFields, fields, setFields, formId, token, formData, setFormData } = props;
    const [newFields, setNewFields] = useState([]);
    const [isUpdated, setIsUpdated] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [status, setStatus] = useState(formData.status);

    useLayoutEffect(() => {
        window.__isReactDndBackendSetUp = false;
    }, []);

    useEffect(() => {
        const newFormFields = savedFields.map(f => ({
            id: f.id,
            data: JSON.stringify(f.data, undefined, 1),
            position: f.position
        }));
        setNewFields(newFormFields);
    }, [savedFields]);

    useEffect(() => {
        const handleElementStoreChange = (state) => {
            let formHasChanged = false;
            const updatedNewFields = state.data.reduce((acc, field, index) => {
                const existingField = newFields.find(f => f.id === field.id);
                const fieldDataStr = JSON.stringify(field, undefined, 1);

                if (!existingField || existingField.position !== index || existingField.data !== fieldDataStr) {
                    formHasChanged = true;
                    acc.push({
                        id: existingField ? existingField.id : undefined,
                        data: fieldDataStr,
                        position: index
                    });
                } else {
                    acc.push(existingField);
                }
                return acc;
            }, []);

            if (formHasChanged) {
                setNewFields(updatedNewFields);
                setIsChanged(true);
            }
        };

        let unsubscribe = ElementStore.subscribe(handleElementStoreChange);

        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();
            }
        };
    }, [newFields, savedFields]);

    const updateFields = async (data) => {
        setIsUpdated(true);
        const updatedFields = await Promise.all(data.map(r => DataServices.updateFormField(token, r.id, r)));
        setFields(updatedFields.map(f => JSON.parse(f.data)));
        setSavedFields(updatedFields);
    };


    const handleSubmit = async () => {
        if(formData.status !== status) {
            let is_visible = status === 'enabled' ? true : false;
            DataServices.updateForm(token, formId, {status: status, is_visible: is_visible}).then(resp => {
                setFormData({
                    ...formData,
                    status: status,
                });
                setIsUpdated(true);
                setTimeout(() => {
                    setIsUpdated(false);
                }, 2000);
            })
            .catch(err => console.log(err));
        }

        if (isSubmitting || !isChanged) return;
        setIsSubmitting(true);

        const currentFieldIds = new Set(savedFields.map(f => f.id));
        const latestFieldIds = new Set(newFields.map(f => f.id));
        const deletedFormIds = [...currentFieldIds].filter(id => !latestFieldIds.has(id));

        try {
            if (deletedFormIds.length > 0) {
                await Promise.all(deletedFormIds.map(id => DataServices.deleteFormField(token, id)));

                const updatedNewFields = newFields.filter(f => !deletedFormIds.includes(f.id));
                const updatedSavedFields = savedFields.filter(f => !deletedFormIds.includes(f.id));
                setNewFields(updatedNewFields);
                setSavedFields(updatedSavedFields);
            }

            const data = await DataServices.updateForm(token, formId, { form_fields: newFields });

            if (newFields.some(field => field.id === undefined)) {
                updateFields(data['form_fields']);
            } else {
                updateFields(newFields);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsSubmitting(false);
            setIsChanged(false);
        }
    };

    return (
        <>
            {isUpdated &&
                <div className="alert alert-success mb-3">Your form has been successfully updated!</div>
            }
            <DemoBar isEdit={true} newFields={newFields} handleSubmit={handleSubmit} disabled={!isChanged} status={status} setStatus={setStatus} />
            <ReactFormBuilder toolbarItems={toolbarItems} edit data={fields} />
        </>
    );
};

export default FormEditBuild;