import { useState, useEffect } from 'react';
import DataServices from '../api/services';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom'

import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faFolderClosed, faGear } from '@fortawesome/free-solid-svg-icons';
import Logo from '../logo.svg';

const Header = (props) => {
    const { authTokens, user } = useAuth();
    const navigate = useNavigate()
    const [showCreateFormPopup, setShowCreateFormPopup] = useState(false);
    const [showCreateFolderPopup, setShowCreateFolderPopup] = useState(false);
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [newFolderName, setNewFolderName] = useState();
    const [error, setError] = useState();
    const { isNavOpen, toggleNav } = props;

    useEffect(() => {
        if (authTokens && user) {
            if (user.id) {
                DataServices.getAllFoldersbyUserID(authTokens, user.id).then(res => {
                    if (res.count > 0) {
                        setFolders(res.results);
                    }
                })
                    .catch(err => console.log(err));
            }
        }
    }, [authTokens, user])

    const handleCreateForm = (event) => {
        setShowCreateFormPopup(true);
    }

    const handleCreateFolder = (event) => {
        event.preventDefault();
        setShowCreateFolderPopup(true);
        setSelectedFolder('create');
    }

    const closeCreateFormPopup = (event) => {
        setShowErrorMessage(false);
        setError();
        setShowCreateFormPopup(false);
    }

    const closeCreateFolderPopup = (event) => {
        setShowErrorMessage(false);
        setError();
        setSelectedFolder();
        setShowCreateFolderPopup(false);
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setShowErrorMessage(false);
        setError();
        if (name === 'folder') {
            setSelectedFolder(value);
            console.log(selectedFolder);
        }

        if (name === 'foldername') {
            setNewFolderName(value);
        }
    }

    const validateField = (e) => {
        const { value } = e.target
        let message = '';

        if (!value) {
            message = 'This field is required';
        }

        setError(message)
    }

    const handleSubmit = (type, e) => {
        if (!selectedFolder) {
            setShowErrorMessage(true);
        } else {
            if (selectedFolder !== 'create') {
                closeCreateFormPopup();
                closeCreateFolderPopup();
                navigate('/create?folder=' + selectedFolder);
            } else {
                if (!newFolderName) {
                    setError('This field is required')
                } else {
                    const fd = new FormData();
                    fd.append('name', newFolderName);
                    fd.append('user', user.id);
                    fd.append('permitted_users', [user.id]);
                    DataServices.createNewFolder(authTokens, fd).then(res => {
                        if (res) {
                            closeCreateFormPopup();
                            closeCreateFolderPopup();
                            if (type === 'create_form') {
                                navigate('/create?folder=' + res.id);
                            } else {
                                navigate('/?updated=true')
                            }
                        }
                    }).catch(err => console.log(err));
                }
            }
        }
    }

    return (
        <>
            <Navbar expand className={`bchu-header ${isNavOpen ? 'sidenav-open' : ''}`}>
                <div className="bchu-header__logo-wrapper">
                    <img src={Logo} className="bchu-header__logo" alt="logo" />
                </div>
                <Navbar.Collapse id="headerNavbar">
                    <Button variant="primary" className="w-100 mb-5" onClick={handleCreateForm}><FontAwesomeIcon icon={faCirclePlus} /> Create Form</Button>
                    <h6 className="fw-light mb-3">FORMS</h6>
                    <Nav.Item>
                        <Link to="/" className="nav-link"><FontAwesomeIcon icon={faFolderClosed} /> All Folders</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link onClick={handleCreateFolder}><FontAwesomeIcon icon={faCirclePlus} /> Create a new folder</Nav.Link>
                    </Nav.Item>
                    <Link to="/account-settings" className="nav-link mt-auto nav-link--lgreen"><FontAwesomeIcon icon={faGear} /> Settings</Link>
                </Navbar.Collapse>
            </Navbar>
            {showCreateFormPopup &&
                <Modal
                    show={showCreateFormPopup}
                    onHide={closeCreateFormPopup}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <h5 className="mb-4">Create a Form</h5>
                        <Form.Group className="mb-4">
                            <Form.Label className="h6">Folder Name</Form.Label>
                            <Form.Select name="folder" onChange={handleChange}>
                                <option value="">Select a folder name</option>
                                <option value="create">Create a new folder</option>
                                {folders && folders.map((folder, i) => (
                                    <option key={i} value={folder.id}>{folder.name}</option>
                                ))}
                            </Form.Select>
                            {showErrorMessage &&
                                <div className="invalid-feedback d-block">Please select a folder name.</div>
                            }
                        </Form.Group>
                        {selectedFolder && selectedFolder === 'create' &&
                            <Form.Group className="mb-4">
                                <Form.Label className="h6">Folder Name</Form.Label>
                                <Form.Control type="text" name="foldername" className="form-control" onBlur={validateField} onChange={handleChange} isInvalid={!!error} />
                                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                            </Form.Group>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeCreateFormPopup}>Cancel</Button>
                        <Button variant="primary" onClick={(e) => handleSubmit('create_form', e)}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }

            {showCreateFolderPopup &&
                <Modal
                    show={showCreateFolderPopup}
                    onHide={closeCreateFolderPopup}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <h5 className="mb-4">Create a Folder</h5>
                        <Form.Group className="mb-4">
                            <Form.Label className="h6">Folder Name</Form.Label>
                            <Form.Control type="text" name="foldername" className="form-control" onBlur={validateField} onChange={handleChange} isInvalid={!!error} />
                            <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeCreateFolderPopup}>Cancel</Button>
                        <Button variant="primary" onClick={(e) => handleSubmit('create_folder', e)}>Save</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default Header;