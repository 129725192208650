import { useState } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

const FormPublish = (props) => {
    const { embeddedCode } = props;
    const [copyEmbedCodeSuccess, setCopyEmbedCodeSuccess] = useState('');
    const handleCopyEmbeddedForm = () => {
        try {
          navigator.clipboard.writeText(embeddedCode);
          setCopyEmbedCodeSuccess('Copied!');
          setTimeout(() => {
            setCopyEmbedCodeSuccess('');
        }, 2000);
        } catch (err) {
          setCopyEmbedCodeSuccess('Failed to copy!');
          setCopyEmbedCodeSuccess('');
        }
    }
    return(
        <div className="main__wrapper">
            <Row className="justify-content-center">
                <Col md={10} xl={7} className="text-center">
                    <h3 className="fw-500 mb-4">Your form is ready!</h3>
                    <p className="font-20 mb-5">Copy & paste this code into your website’s <br className="d-none d-lg-block" /> HTML where you want the form to appear.</p>
                    <div className="create__embed">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <h5 className="fw-bold mb-0">Embedded Form Code</h5>
                            <Button variant="primary" onClick={handleCopyEmbeddedForm}><FontAwesomeIcon icon={faCopy} /> Copy Code</Button>
                        </div>
                        <Form.Control as="textarea" rows={6} className="form-textarea-white" value={embeddedCode} readOnly />
                        {copyEmbedCodeSuccess}
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default FormPublish;