import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import DataServices from '../api/services';
import Logo from '../logo.svg';

function PasswordResetConfirm() {
    const { uid, token } = useParams(); // extract uid and token from URL
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handlePasswordSubmit = async (event) => {
        event.preventDefault();
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            await DataServices.resetPasswordConfirm(uid, token, newPassword);
            console.log(token)

            setMessage('Your password has been reset successfully.');
            setTimeout(() => navigate('/login'), 3000); // redirect to login after successful reset
        } catch (error) {
            console.error('Error resetting password:', error);
            setError(error.message);
        }
    };

    return (
        <Container className="d-flex justify-content-center align-items-center forgot-password-container">
            <div className="w-100 password-reset-form">
                <Row className="mb-4 text-center">
                <Col md={8} className="text-md-start text-center">
                    <img src={Logo} className="mb-5 login__logo" alt="Logo" width="250px" />
                    <h3 className="mb-3">Password Reset</h3>
                    <p className="mb-0">Enter your new password below.</p>
                </Col>
                </Row>
                <Form onSubmit={handlePasswordSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control 
                            type="password" 
                            name="new_password" 
                            value={newPassword} 
                            onChange={(e) => setNewPassword(e.target.value)}
                            required 
                        />
                    </Form.Group>
                    <Form.Group className="mb-4">
                        <Form.Label>Confirm New Password</Form.Label>
                        <Form.Control 
                            type="password" 
                            name="confirm_password" 
                            value={confirmPassword} 
                            onChange={(e) => setConfirmPassword(e.target.value)} 
                            required 
                        />
                    </Form.Group>
                    <Button variant="primary" type="submit" className="w-100">Reset Password</Button>
                </Form>
                {message && <div className="alert alert-success mt-3">{message}</div>}
                {error && <div className="alert alert-danger mt-3">{error}</div>}
            </div>
        </Container>
    );
}

export default PasswordResetConfirm;
