import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import DataServices from "../api/services";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { ElementStore, ReactFormGenerator } from 'react-form-builder2';

import Layout from "../components/layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderClosed, faArrowLeftLong, faCirclePlus, faAngleRight, faFileExport, faTrash } from '@fortawesome/free-solid-svg-icons';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PaginationComponent from "../components/pagination";
import { Modal } from "react-bootstrap";


const Submissions = (props) => {
    let params = useParams();
    const navigate = useNavigate();
    const { authTokens } = useAuth();
    const id = params.id;
    const {isNavOpen, setIsNavOpen} = props;

    const [folderName, setFolderName] = useState('');
    const [formName, setFormName] = useState('');
    const [submissions, setSubmissions] = useState([]);
    const [sortOption, setSortOption] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);
    const [csvData, setCSVData] = useState([]);
    const [csvHeaders, setCSVHeaders] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [formData, setFormData] = useState([]);
    const [fields, setFields] = useState([]);
    const [showDeleteSubmissionPopUp, setShowDeleteSubmissionPopUp] = useState(false);
    const [subIdToDelete, setSubIdtoDelete] = useState('');
    const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
    const pageTopRef = useRef(null);

    const findFieldDataByID = (array, id) => {
        return array.filter((element) => {
          return element.id === id;
        })
    };

    const getIndexById = (arr, value) => {
        for(var i = 0; i < arr.length; i++) {
            if(arr[i]['id'] === value) {
                return i;
            }
        }
        return -1;
    }

    const loadData = (authTokens, id, page, pageSize, searchQuery, sortOption, fields) => {
        DataServices.getFormById(authTokens, id).then(resp => {
            const inputFields = [];
            let headers = [];
            if (resp.form_fields.length > 0) {
                let getAllFields = [];
                resp.form_fields.map((r) => {
                    const inputData = JSON.parse(r.data);
                    getAllFields.push(inputData);
                });
                if(getAllFields.length > 0) {
                    getAllFields.map((f) => {
                        if(f.childItems) {
                            f.childItems.map((child) => {
                                const childData = findFieldDataByID(getAllFields, child);
                                if(childData[0].field_name) {
                                    const exists = inputFields.some(item => item.id === childData[0].id);
                                    if(!exists) {
                                        inputFields.push(childData[0]);
                                    }
                                }
                            });
                        }else if(f.field_name) {
                            const exists = inputFields.some(item => item.id === f.id);
                            if(!exists) {
                                inputFields.push(findFieldDataByID(getAllFields, f.id)[0]);
                            }
                        }
                    });
                }
                inputFields.map((input) => {
                    let setLabel = input.label ? input.label.replace(/(<([^>]+)>)/gi, "") : input.text;
                    if(setLabel.trim().length === 0) {
                        if(input.parentId && input.col >= 0) {
                            const getParent = findFieldDataByID(fields, input.parentId)[0];
                            if(getParent && getParent.childItems.length > 0) {
                                const getFirstColumn = findFieldDataByID(fields, getParent.childItems[0])[0];
                                const getParentIndex = getIndexById(fields, input.parentId);
                                if(getFirstColumn.element === 'Label') {
                                    setLabel = getFirstColumn.content ? getFirstColumn.content.replace(/(<([^>]+)>)/gi, "") : '';
                                }
                                for(var i = getParentIndex - 1; ((i < getParentIndex) && (i > -1)); i--) {
                                    if(fields[i].childItems) {
                                        const getChildColData = findFieldDataByID(fields, fields[i].childItems[input.col])[0];
                                        if(getChildColData.element === 'Label') {
                                            setLabel += ' ' + getChildColData.content ? getChildColData.content.replace(/(<([^>]+)>)/gi, "") : '';
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    headers.push({
                        label: setLabel,
                        key: input.field_name
                    });
                });
                headers.push({
                    label: "Date Submitted",
                    key: "date"
                });
                setCSVHeaders(headers);
            }
            DataServices.getAllSubmissionsByFormId(authTokens, id).then(response => {
                if (response.count > 0) {
                    setTotalItems(response.count);
                    let results = [];
                    response.results.map((r, index) => {
                        const json = JSON.parse(r.json_blob)['data'];
                        let getFields = { "date": r.created };
                        inputFields.map((input) => {
                            json.map((data) => {
                                if(data.custom_name === input.field_name) {
                                    if (data.custom_name.includes('checkboxes') || data.custom_name.includes('radiobuttons')) {
                                        let getTextValues = [];
                                        if(findFieldDataByID(inputFields, data.id)) {
                                            findFieldDataByID(inputFields, data.id)[0].options.map((option) => {
                                                data.value.map((val) => {
                                                    if (option.key === val) {
                                                        let textValue = option.text !== '' ? option.text : 'true';
                                                        getTextValues.push(textValue);
                                                    }
                                                });
                                            });
                                            getFields = {
                                                ...getFields,
                                                [data.name]: getTextValues.join(', ')
                                            };
                                        }
                                        if(data.label.trim().length < 1 && data.value.length < 1) {
                                            getFields = {
                                                ...getFields,
                                                [data.name]: 'false'
                                            };
                                        }
                                    } else if(data.custom_name.includes('image')) {
                                        fields.map((f) => {
                                            if(data.custom_name === f.field_name) {
                                                getFields = {...getFields,
                                                    [data.name]: f.src
                                                };
                                            }
                                        });
                                    } else if(data.custom_name.includes('file_upload') && r.files.length > 0) {
                                        r.files.map((file) => {
                                            if(data.filename && file.file.includes(data.filename)) {
                                                getFields = {...getFields,
                                                    [data.name]: file.file,
                                                };
                                            }
                                        });
                                    } else {
                                        getFields = {
                                            ...getFields,
                                            [data.name]: data.value
                                        };
                                    }
                                }
                            });
                        });
                        results = [...results, getFields];
                    });
                    setCSVData(results);
                }
            })
                .catch(err => console.log(err));
            DataServices.getSubmissionsByFormId(authTokens, id, page, pageSize, searchQuery, sortOptionMap[sortOption]).then(res => {
                if (res.count > 0) {
                    let getResults = [];
                    res.results.map((r) => {
                        let getFields = [];
                        let name = '';
                        let email = '';
                        const json = JSON.parse(r.json_blob)['data'];
                        json.map((data) => {
                            if (data.label && data.label.toLowerCase().includes('name')) {
                                name = data.value;
                            }
    
                            if (data.label && data.label.toLowerCase().includes('mail')) {
                                email = data.value;
                            }
                            getFields.push({
                                label: data.label,
                                value: data.value
                            });
                        });
                        getResults.push({
                            id: r.id,
                            date: format(r.created, 'MMMM d, yyyy'),
                            data: getFields,
                            name: name,
                            email: email
                        })
                    });
                    setSubmissions(getResults);
                }
            })
                .catch(err => console.log(err));
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        if (authTokens && id) {
            let getFields = [];
            DataServices.getFormById(authTokens, id).then(resp => {
                setFormName(resp.name);
                if (resp.form_fields.length > 0) {
                    resp.form_fields.map((r) => {
                        const inputData = JSON.parse(r.data);
                        getFields.push(inputData);
                    });
                    setFields(getFields);
                }
                DataServices.getFolderById(authTokens, resp.folder).then(resFolder => {
                    setFolderName(resFolder.name);
                }).catch(err => console.log(err));
            }).catch(err => console.log(err));
            if(getFields) {
                loadData(authTokens, id, page, pageSize, searchQuery, sortOption, getFields);
            }
            
            // if (sortOption) {
            //     let sortedSubmission = [...submissions];

            //     switch (sortOption) {
            //         case '2':
            //         sortedSubmission.sort((a, b) => a.name.localeCompare(b.name));
            //         break;
            //         default:
            //     }
            //     setSubmissions(sortedSubmission); // Set the sorted forms back into state
            // }
        } else {
            navigate('/');
        }
    }, [authTokens, id, pageSize, page])

    const sortOptionMap = {
        '1': 'created',
        '2': 'name',
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value); // Update the search query state
    };

    //add submission
    ElementStore.subscribe(formData => handleUpdate(formData));

    const handleAddModal = () => {
        setShowAddModal(true);
    };

    const closeAddModal = () => {
        setShowAddModal(false);
    }

    const handleDeleteAllModal = () => {
        setShowDeleteAllModal(true)
    }

    const closeDeleteAllModal = () => {
        setShowDeleteAllModal(false);
    }

    const handleUpdate = (data) => {
        setFormData({
            ...formData,
            data
        });
        // console.log(formData);
    }

    const handleSubmit = (event) => {
        const dataSubmit = new FormData();
        {
            event.map((fd, i) => {
                fields.map((f) => {
                    if (f.id) {
                        if (f.id === fd.id) {
                            fd['label'] = f.label;
                        }
                    }
                });
                if(fd.custom_name.includes('file_upload')){
                    if(fd.value) {
                        dataSubmit.append('files', fd.value);
                        fd['filename'] = fd.value.name;
                    }
                }
            })
        }
        dataSubmit.append('source_url', window.location.href);
        dataSubmit.append('json_blob', JSON.stringify({data: event}, undefined, 1));
        dataSubmit.append('target_form', id);
        
        DataServices.createSubmission(authTokens, dataSubmit).then(res => {
            setPage(1);
            loadData(authTokens, id, page, pageSize, searchQuery, sortOption, fields);
            setShowAddModal(false);
        });
    }

    const handleDeleteSubmission = (id) => {
        setShowDeleteSubmissionPopUp(true);
        setSubIdtoDelete(id);
    }

    const closeDeleteSubmissionPopUp = (event) => {
        setShowDeleteSubmissionPopUp(false);
    }

    const handleDeleteButton = async (id) => {
        if (id) {
            await DataServices.deleteSubmission(authTokens, id).then(res => {
                if (res) {
                    closeDeleteSubmissionPopUp();
                    setSubmissions(submissions.filter(submission => submission.id !== id));
                }
            }).catch(err => console.log(err));
        }
    }

    const handleDeleteAllButton = async (formId) => {
        if (id) {
            await DataServices.deleteAllSubmissions(authTokens, formId).then(res => {
                if (res) {
                    closeDeleteAllModal();
                    setSubmissions([]);
                }
            }).catch(err => console.log(err));
        }
    }

    return (
        <>
            <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
                {folderName && formName &&
                    <div className="main__wrapper" ref={pageTopRef}>
                        <div className="mb-4">
                            <Link onClick={() => navigate(-1)} className="back-button"><FontAwesomeIcon icon={faArrowLeftLong} /> Back</Link>
                        </div>



                        <Row className="align-items-center justify-content-md-between mb-3">
                            <Col md="auto" className="mb-3 mb-md-0">
                                <h4>Submissions</h4>
                            </Col>
                            <Col md className="d-flex flex-wrap align-items-center">

                                {csvData &&
                                    <CSVLink className="btn btn-primary ms-auto"
                                        headers={csvHeaders}
                                        data={csvData}
                                        filename="submissions.csv"
                                        target="_blank"
                                    >
                                        <FontAwesomeIcon icon={faFileExport} /> Export
                                    </CSVLink>
                                }
                                <Button variant="primary" className="ms-1" onClick={() => handleAddModal()}><FontAwesomeIcon icon={faCirclePlus} /> Add submission</Button>
                            </Col>
                        </Row>


                        <Row className="align-items-center justify-content-md-between">
                            <Col md="auto" className="mb-3 mb-md-0">
                                <h5 className="mb-0"><FontAwesomeIcon icon={faFolderClosed} className="mb-0 me-2 h3" /> {folderName} <FontAwesomeIcon icon={faAngleRight} className="mx-3" /> {formName}</h5>
                            </Col>
                            <Col md className="d-flex flex-wrap align-items-center">

                                <Button variant="danger" className="ms-auto" onClick={() => handleDeleteAllModal()}><FontAwesomeIcon icon={faTrash} /> Delete all</Button>
                            </Col>
                        </Row>
                        <hr></hr>
                        <Row className="justify-content-md-between">
                            <Col md={6} xl={5} className="mb-4">
                                <Form.Control type="search" name="search" placeholder="Search form name" value={searchQuery} onChange={handleSearchChange}></Form.Control>
                            </Col>
                            <Col md={6} className="mb-4">
                                <div className="d-flex justify-content-md-end align-items-center gap-3">
                                    <span className="col-auto text-small">Sort by</span>
                                    <Form.Select className="w-auto select--sort" value={sortOption} onChange={handleSortChange}>
                                        <option value="1">Submission Date</option>
                                        <option value="2">Name</option>
                                    </Form.Select>
                                </div>
                            </Col>
                        </Row>


                        <div className="bchu-list bchu-list--submissions">
                            {
                                submissions && submissions.length > 0 ? (
                                    submissions.map((s, i) => (
                                        <div key={i} className="bchu-list-item">
                                            <div className="bchu-list-item__info">
                                                <h5 className="mb-2">{s.name}</h5>
                                                <div className="bchu-list-item__info-meta">{s.email} <span className="separator">|</span> {s.date}</div>
                                            </div>
                                            <div className="bchu-list-item__actions">
                                                <Link to={"/submissions/view/" + s.id}>View Submission</Link>
                                                <NavDropdown title="More" className="dark-arrow">
                                                    <NavDropdown.Item onClick={() => handleDeleteSubmission(s.id)}>Delete</NavDropdown.Item>
                                                </NavDropdown>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-submissions-message my-3">No submissions.</div>
                                )
                            }

                        </div>
                        <hr></hr>
                        {totalItems > pageSize &&
                            <PaginationComponent
                                itemsCount={totalItems}
                                itemsPerPage={pageSize}
                                currentPage={page}
                                setPage={setPage}
                                pageTopRef={pageTopRef}
                            />
                        }
                    </div>
                }
            </Layout>
            {showAddModal &&
                <Modal
                    className="bchu-edit-submission"
                    show={showAddModal}
                    onHide={closeAddModal}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <ReactFormGenerator
                            data={fields}
                            onSubmit={handleSubmit}
                        />
                        <Button variant="outline-primary" onClick={closeAddModal} className="btn-cancel">Cancel</Button>
                    </Modal.Body>
                </Modal>
            }
            {showDeleteSubmissionPopUp &&
                <Modal
                    show={showDeleteSubmissionPopUp}
                    onHide={closeDeleteSubmissionPopUp}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <h6 className="mb-4">Are you sure you want to delete this submission?</h6>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeDeleteSubmissionPopUp}>Cancel</Button>
                        <Button variant="primary" onClick={() => handleDeleteButton(subIdToDelete)}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            }
            {showDeleteAllModal &&
                <Modal
                    show={showDeleteAllModal}
                    onHide={closeDeleteAllModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <h6 className="mb-4">Are you sure you want to delete all submissions?</h6>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeDeleteAllModal}>Cancel</Button>
                        <Button variant="primary" onClick={() => handleDeleteAllButton(id)}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default Submissions;