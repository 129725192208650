import { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import DataServices from '../api/services';

import Layout from '../components/layout';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const AccountSettings = (props) => {
    const { authTokens, user, } = useAuth();
    const {isNavOpen, setIsNavOpen} = props;
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [userData, setUserData] = useState({});
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
        staff: {
            phone_no: '',
            address: '',
            ip_address: '',
            role: ''
        }
    });


    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        username: '',
        email: '',
    })
    const [isGenSetChange, setIsGenSetChange] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        if (user) {
            DataServices.getUserInfo(authTokens, user.id).then(res => {
                if (res) {
                    setUserData(res);
                    setFormData({
                        ...res, // other user data
                        staff: {
                            ...res.staff // ensure staff data, including role, is included
                        }
                    });
                }
            })
                .catch(err => console.log(err));
        }
    }, [authTokens, user])

    const validateAll = () => {
        const { email, first_name, last_name, username } = formData;
        let isValid = true

        if (!first_name) {
            errors.first_name = 'This field is required';
            isValid = false
        }

        if (!last_name) {
            errors.last_name = 'This field is required';
            isValid = false
        }

        if (!username) {
            errors.username = 'This field is required';
            isValid = false
        }

        if (!email) {
            errors.email = 'This field is required';
            isValid = false
        }

        if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email format must be as example@mail.com';
            isValid = false
        }

        if (!isValid) {
            setErrors(errors);
        }

        return isValid;
    }

    const handleGeneralSubmit = (event) => {
        event.preventDefault()
        setIsLoading(true)
        const isValid = validateAll();
        if (isValid) {
            const fd = new FormData(event.currentTarget)
            DataServices.updateUserInfo(authTokens, user.id, fd).then(res => {
                if (res) {
                    setIsLoading(false)
                    setIsGenSetChange(false)
                    setIsSuccess(true)
                    setTimeout(() => {
                        setIsSuccess(false);
                    }, 2000);
                }
            })
                .catch(err => console.log(err));
        }
        setIsLoading(false)
    }

    const handlePasswordSubmit = async (event) => {
        event.preventDefault();
        setMessage("");
        setErrorMessage("");


        if (newPassword !== confirmPassword) {
            setErrorMessage("New passwords do not match.");
            return;
        }
        try {
            await DataServices.changePassword(authTokens, currentPassword, newPassword);
            setMessage("Your password has been successfully changed.");
        } catch (error) {
            console.error(error);
            setErrorMessage("Please enter the correct current password to proceed.");
        }
    };

    const handleEmailSubmit = async (event) => {
        event.preventDefault();
        const emailSettings = {
            email: formData.email
        };
        setIsLoading(true);
        try {
            await DataServices.updateUserEmail(authTokens, user.id, emailSettings);
            setIsSuccess(true);

        } catch (err) {
            console.error(err);
            // Handle error - update UI or set an error state
            // e.g., setError("Failed to update email");
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                setIsSuccess(false);
            }, 2000);
        }
    };

    // const handleChange = event => {
    //     setFormData({ ...formData, [event.target.name]: event.target.value });
    //     setIsGenSetChange(true);
    // };

    const handleChange = (event) => {
        const { name, value } = event.target;

        if (name.includes('.')) {
            const [parentKey, childKey] = name.split('.');

            if (parentKey === 'staff' && childKey) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [parentKey]: {
                        // Spread existing child objects or initialize an empty object
                        ...prevFormData[parentKey] || {},
                        [childKey]: value
                    }
                }));
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }

        setIsGenSetChange(true);
    };


    const validateField = (e) => {
        const { name } = e.target
        const value = formData[name]
        let message = '';

        if (!value) {
            message = 'This field is required';
        }

        if (name === 'email' && !/\S+@\S+\.\S+/.test(value)) {
            message = 'Email format must be as example@mail.com';
        }

        setErrors({ ...errors, [name]: message })
    }

    const handleCancel = event => {
        event.preventDefault();
        setFormData(userData);
    }

    return (
        <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
            <div className="main__wrapper">
                {isSuccess && (
                    <div className="alert alert-success mb-3">Your profile has been successfully updated!</div>
                )}
                <h4 className="mb-4">Account Settings</h4>
                <Form onSubmit={handleGeneralSubmit}>
                    <Row className="mb-4 justify-content-md-between">
                        <Col lg={10} xl={6} className="mb-3 mb-md-0">
                            <h5>General</h5>
                            <p>Update your display name, username, email and role.</p>
                        </Col>
                        <Col md="auto" className="d-flex align-items-center gap-2">
                            <Button variant="outline-primary" size="sm" disabled={isGenSetChange ? '' : 'disabled'} onClick={handleCancel}>Cancel</Button>
                            <Button variant="primary" size="sm" type="submit" disabled={!isGenSetChange || isLoading ? 'disabled' : ''}>{isLoading ? 'Loading...' : 'Save'}</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">First Name</Form.Label>
                                <Form.Control type="text" name="first_name" value={formData.first_name || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!errors.first_name} />
                                <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Last Name</Form.Label>
                                <Form.Control type="text" name="last_name" value={formData.last_name || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!errors.last_name} />
                                <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Username</Form.Label>
                                <Form.Control type="text" name="username" value={formData.username || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!errors.username} />
                                <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Email Address</Form.Label>
                                <Form.Control type="email" name="email" value={formData.email || ''} onChange={handleChange} onBlur={validateField} isInvalid={!!errors.email} />
                                <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                            </Form.Group>

                            {/* <Form.Group className="mb-4">
                                <Form.Label className="h5">Phone Number</Form.Label>
                                <Form.Control type="text" name="staff.phone_no" value={formData.staff?.phone_no || ''} onChange={handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Address</Form.Label>
                                <Form.Control type="text" name="staff.address" value={formData.staff?.address || ''} onChange={handleChange} />
                            </Form.Group> */}

                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Role</Form.Label>
                                {
                                    formData.staff?.role === 'admin' ? (
                                    <Form.Select
                                        name="staff.role" // Reflects the nested structure
                                        value={formData.staff?.role || ''} // Bind to nested state
                                        onChange={handleChange}
                                    >
                                        <option value="">Select role...</option>
                                        <option value="admin">Admin</option>
                                        <option value="staff">Staff</option>
                                        <option value="guest">Guest</option>
                                    </Form.Select>
                                    ) : (
                                        <Form.Control type="text" value={formData.staff?.role || 'staff'} disabled readOnly />
                                    )
                                }
                                
                            </Form.Group>


                        </Col>
                    </Row>
                </Form>
                <hr></hr>
                <Form onSubmit={handlePasswordSubmit}>
                    <Row className="mb-4 justify-content-md-between">
                        <Col lg={10} xl className="mb-3 mb-md-0">
                            <h5>Password Reset</h5>
                            <p>Reset your password by providing the current and the new password.</p>
                        </Col>
                        <Col md="auto" className="d-flex align-items-center gap-2">
                            <Button variant="outline-primary" size="sm">Cancel</Button>
                            <Button variant="primary" size="sm" type="submit">Save</Button>
                        </Col>
                    </Row>
                    {/* ... rest of your form components */}
                    <Row>
                        <Col xl={6}>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Current Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="current_password"
                                    value={currentPassword}
                                    onChange={e => setCurrentPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="new_password"
                                    value={newPassword}
                                    onChange={e => setNewPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="confirm_password"
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* ... other form components */}
                    {message && <div className="alert alert-success">{message}</div>}
                    {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                </Form>
                {/* <hr></hr> */}
                {/* <Form onSubmit={handleEmailSubmit}>
                    <Row className="mb-4 justify-content-md-between">
                        <Col lg={10} xl className="mb-3 mb-md-0">
                            <h5>Email Settings</h5>
                            <p>Control how you want to receive communications.</p>
                        </Col>
                        <Col md="auto" className="d-flex align-items-center gap-2">
                            <Button variant="outline-primary" size="sm">Cancel</Button>
                            <Button variant="primary" size="sm" type="submit">Save</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={6}>
                            <Form.Group className="mb-4">
                                <Form.Label className="h5">Email attachments</Form.Label>
                                <Form.Select>
                                    <option></option>
                                    <option value="enabled">Enabled</option>
                                    <option value="disabled">Disabled</option>
                                </Form.Select>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label className="h5">Recipient Message Body</Form.Label>
                                <Form.Control as="textarea" rows={4} />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form> */}
            </div>
        </Layout>
    );
}

export default AccountSettings;