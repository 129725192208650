
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import DataServices from "../api/services";
import { CSVLink } from "react-csv";
import { ElementStore, ReactFormGenerator } from "react-form-builder2";

import Layout from "../components/layout";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faFolderClosed, faArrowLeftLong, faFileExport, faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import 'react-form-builder2/dist/app.css';

const ViewSubmission = (props) => {
    let params = useParams();
    const navigate = useNavigate();
    const { authTokens } = useAuth();
    const id = params.id;
    const {isNavOpen, setIsNavOpen} = props;

    const [folderName, setFolderName] = useState('');
    const [formName, setFormName] = useState('');
    const [formData, setFormData] = useState([]);
    const [fields, setFields] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [name, setName] = useState('');
    const [dateSubmitted, setDateSubmitted] = useState('');
    const [csvData, setCSVData] = useState([]);
    const [csvHeaders, setCSVHeaders] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [info, setInfo] = useState('');

    const findFieldDataByID = (array, id) => {
        return array.filter((element) => {
          return element.id === id;
        })
    };

    const getDataValue = (array, id, fields, files) => {
        let value = '';
        array.map((detail) => {
            if(id === detail.id) {
                if(detail.custom_name.includes('checkboxes') || detail.custom_name.includes('radiobuttons')) {
                    let getTextValues = [];
                    fields.map((f) => {
                        if(detail.id === f.id) {
                            f.options.map((option) => {
                                detail.value.map((val) => {
                                    if(option.key === val) {
                                        getTextValues.push('&#x2713; '+option.text);
                                    }
                                });
                            });
                        }
                    });
                    value = getTextValues.join('<br>');
                } else if(detail.custom_name.includes('image')) {
                    fields.map((f) => {
                        if(detail.id === f.id) {
                            value = '<img src="'+f.src+'" />';
                        }
                    });
                } else if(detail.custom_name.includes('file_upload') && files.length > 0) {
                    files.map((file) => {
                        if(detail.filename && file.file.includes(detail.filename)) {
                            value = '<a href="'+file.file+'">'+detail.filename+'</a>';
                        }
                    });
                } 
                else {
                    value = detail.value;
                }
            }
        });
        return value;
    }

    const getIndexById = (arr, value) => {
        for(var i = 0; i < arr.length; i++) {
            if(arr[i]['id'] === value) {
                return i;
            }
        }
        return -1;
    }

    const getDetails = (data, fields, date, files) => {
        const inputFields = [];
        let getCSVdata = {"date": date};
        let headers = [];
        // console.log(fields)
        fields.map((f) => {
            if(f.childItems) {
                f.childItems.map((child) => {
                    const childData = findFieldDataByID(fields, child);
                    if(childData[0].field_name) {
                        const exists = inputFields.some(item => item.id === childData[0].id);
                        if(!exists) {
                            inputFields.push(childData[0]);
                        }
                    }
                });
            }else if(f.field_name) {
                const exists = inputFields.some(item => item.id === f.id);
                if(!exists) {
                    inputFields.push(findFieldDataByID(fields, f.id)[0]);
                }
            }
        });
        inputFields.map((input) => {
            let setLabel = input.label ? input.label.replace(/(<([^>]+)>)/gi, "") : input.text;
            if(setLabel.trim().length === 0) {
                if(input.parentId && input.col >= 0) {
                    const getParent = findFieldDataByID(fields, input.parentId)[0];
                    const getFirstColumn = findFieldDataByID(fields, getParent.childItems[0])[0];
                    const getParentIndex = getIndexById(fields, input.parentId);
                    if(getFirstColumn.element === 'Label') {
                        setLabel = getFirstColumn.content ? getFirstColumn.content.replace(/(<([^>]+)>)/gi, "") : '';
                    }
                    for(var i = getParentIndex - 1; ((i < getParentIndex) && (i > -1)); i--) {
                        if(fields[i].childItems) {
                            const getChildColData = findFieldDataByID(fields, fields[i].childItems[input.col])[0];
                            if(getChildColData.element === 'Label') {
                                setLabel += ' ' + getChildColData.content ? getChildColData.content.replace(/(<([^>]+)>)/gi, "") : '';
                                break;
                            }
                        }
                    }
                }
            }
            headers.push({
                label: setLabel,
                key: input.field_name
            });
        });
        data.map((detail, i) => {
            if(detail.custom_name.includes('checkboxes') || detail.custom_name.includes('radiobuttons')) {
                let getTextValues = [];
                fields.map((f) => {
                    if(detail.custom_name === f.field_name) {
                        f.options.map((option) => {
                            detail.value.map((val) => {
                                if(option.key === val) {
                                    let textValue = option.text !== '' ? option.text : 'true';
                                    getTextValues.push(textValue);
                                }
                            });
                        });
                    }
                });
                getCSVdata = {...getCSVdata,
                    [detail.name]: getTextValues.join(', ')
                };
                if(detail.label.trim().length < 1 && detail.value.length < 1) {
                    getCSVdata = {
                        ...getCSVdata,
                        [detail.name]: 'false'
                    };
                }
            } else if(detail.custom_name.includes('image')) {
                fields.map((f) => {
                    if(detail.custom_name === f.field_name) {
                        getCSVdata = {...getCSVdata,
                            [detail.name]: f.src
                        };
                    }
                });
            } else if(detail.custom_name.includes('file_upload') && files.length > 0) {
                files.map((file) => {
                    if(detail.filename && file.file.includes(detail.filename)) {
                        getCSVdata = {...getCSVdata,
                            [detail.name]: file.file,
                        };
                    }
                });
            } 
            else {
                getCSVdata = {...getCSVdata,
                    [detail.name]: detail.value
                };
            }
        });
        headers.push({
            label: "Date Submitted",
            key: "date"
        });
        setCSVData([getCSVdata]);
        setCSVHeaders(headers);

        let message = '<table class="mb-0"><tbody><tr><td>';
        let listFields = [];
        if(fields) {
            fields.map((f) => {
                if(f.childItems) {
                    message += '<table><tbody><tr>';
                    f.childItems.map((child) => {
                        if(!listFields.includes(child)) {
                            let label = findFieldDataByID(fields, child)[0];
                            let value = getDataValue(data, child, fields, files);
                            if(child.element === 'Image') {
                                message += '<td><img src="'+ child.src +'" /></td>';
                            }else {
                                if(label.label) {
                                    label = label.label;
                                }else {
                                    label = label.content;
                                }
                                message += '<td><div><div><strong>'+ label + '</strong></div>' + value +'</div></td>';
                            }
                            listFields.push(child);
                        }
                    });
                    message += '</tr></tbody></table>';
                }else {
                    if(!listFields.includes(f.id)) {
                        if(f.element === "LineBreak") {
                            message += '<table><tbody><tr><td><hr /></td></tr></tbody></table>';
                        } else {
                            if(f.element === 'Image') {
                                message += '<table><tbody><tr><td><img src="'+ f.src +'" /></td></tr></tbody></table>';
                            } else {
                                if(f.label) {
                                    let label = f.label;
                                    let value = getDataValue(data, f.id, fields, files) ?? '';
                                    message += '<table><tbody><tr><td><div><div><strong>' + label + '</strong></div>' + value +'</div></td></tr></tbody></table>';
                                }else {
                                    message += '<table><tbody><tr><td><div>' + f.content +'</div></td></tr></tbody></table>';
                                }
                            }
                        }
                        listFields.push(f.id);
                    }
                }
            });
        }
        message += '</td></tr></tbody></table>';
        setInfo(message);
    };

    useEffect(() => {
        if(authTokens && id) {
            DataServices.getSubmissionById(authTokens, id).then(res => {
                setDateSubmitted(res.created);
                setAnswers(JSON.parse(res.json_blob)['data']);
                JSON.parse(res.json_blob)['data'].map((data) => {
                    if(data.label && data.label.toLowerCase().includes('name')) {
                        setName(data.value);
                    }
                });
                DataServices.getFormById(authTokens, res.target_form).then(resp => {
                    setFormName(resp.name);
                    let getFormFields = [];
                    resp.form_fields.map((r) => {
                        getFormFields.push(JSON.parse(r.data));
                    })
                    setFields(getFormFields);
                    getDetails(JSON.parse(res.json_blob)['data'], getFormFields, res.created, res.files);
                    DataServices.getFolderById(authTokens, resp.folder).then(resFolder => {
                        setFolderName(resFolder.name);
                    })
                    .catch(err => console.log(err));
                }).catch(err => console.log(err));
            })
            .catch(err => console.log(err));
            ElementStore.subscribe(formData => handleUpdate(formData));
        }else {
            navigate('/');
        }
    }, [authTokens, id, formData]);

    const handleEditModal = () => {
        setShowEditModal(true);
    };

    const closeEditModal = () => {
        setShowEditModal(false);
    }

    const handleUpdate = (data) => {
        setFormData(data);
    }

    const handleSubmit = (event) => {
        const dataSubmit = new FormData();
        {event.map((fd, i) => {
            fields.map((f) => {
                if(f.id) {
                    if(f.id === fd.id) {
                        fd['label'] = f.label;
                    }
                }
            });
            if(fd.custom_name.includes('file_upload')){
                if(fd.value) {
                    dataSubmit.append('files', fd.value);
                    fd['filename'] = fd.value.name;
                }
            }
        })}
        dataSubmit.append('json_blob', JSON.stringify({data: event}, undefined, 1));

        DataServices.updateSubmission(authTokens, id, dataSubmit).then(res => {
            setAnswers(JSON.parse(res.json_blob)['data']);
            getDetails(JSON.parse(res.json_blob)['data'], fields, dateSubmitted, res.files);
            setShowEditModal(false);
        });
    }

    const handleDeleteModal = () => {
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
    }

    const handleDeleteButton = async (id) => {
        if (id) {
            await DataServices.deleteSubmission(authTokens, id).then(res => {
                if (res) {
                    closeDeleteModal();
                    navigate(-1);
                }
            }).catch(err => console.log(err));
        }
    }

    return(
        <>
            <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
                {folderName && formName &&
                    <div className="main__wrapper">
                        <div className="mb-4">
                            <Link onClick={() => navigate(-1)} className="back-button"><FontAwesomeIcon icon={faArrowLeftLong} /> Back</Link>
                        </div>
                        <h4>Submissions</h4>
                        <Row className="align-items-center justify-content-md-between">
                            <Col md className="mb-3 mb-md-0">
                                <h5 className="mb-0"><FontAwesomeIcon icon={faFolderClosed} className="mb-0 me-2 h3" /> {folderName} <FontAwesomeIcon icon={faAngleRight} className="mx-3" /> {formName}</h5>
                            </Col>
                            <Col md="auto">
                                {csvData &&
                                <CSVLink className="btn btn-primary"
                                    headers={csvHeaders}
                                    data={csvData}
                                    filename="submissions.csv"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faFileExport} /> Export
                                </CSVLink>
                                }
                            </Col>
                        </Row>
                        <hr className="mb-4"></hr>
                        <Row className="justify-content-md-between mb-4">
                            <Col md={6} xl={5} className="mb-4">
                                <h5>{name}</h5>
                            </Col>
                            <Col md={6} className="mb-4">
                                <div className="d-flex justify-content-md-end align-items-center gap-4">
                                    <Button variant="link" onClick={() => handleEditModal()}><FontAwesomeIcon icon={faPenToSquare} className="color-primary me-1" /> Edit</Button>
                                    <Button variant="link" onClick={() => handleDeleteModal()}><FontAwesomeIcon icon={faTrash} className="color-primary me-1" /> Delete Submission</Button>
                                </div>
                            </Col>
                        </Row>
                        <div className="submission-details" dangerouslySetInnerHTML={{__html: info}}></div>
                    </div>
                }
            </Layout>
            {showEditModal &&
                <Modal
                    className="bchu-edit-submission"
                    show={showEditModal}
                    onHide={closeEditModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <ReactFormGenerator
                            data={fields}
                            answer_data={answers}
                            onSubmit={handleSubmit}
                            // onChange={handleUpdate}
                        />
                        <Button variant="outline-primary" onClick={closeEditModal} className="btn-cancel">Cancel</Button>
                    </Modal.Body>
                </Modal>
            }
            {showDeleteModal &&
                <Modal
                    show={showDeleteModal}
                    onHide={closeDeleteModal}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <h6 className="mb-4">Are you sure you want to delete this submission?</h6>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeDeleteModal}>Cancel</Button>
                        <Button variant="primary" onClick={() => handleDeleteButton(id)}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            }
        </>
    );
}

export default ViewSubmission;