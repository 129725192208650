import React from 'react';
import { ReactFormGenerator, ElementStore } from 'react-form-builder2';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';

export default class Demobar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false
    };
  }

  componentDidMount() {
    ElementStore.subscribe(state => this.onChange(state.data));
    if (this.props.isEdit) {
      this.setState({
        data: this.props.newFields,
      });
    }
  }

  showPreview() {
    this.setState({
      previewVisible: true,
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false,
    });
  }

  onChange = (data) => {
    this.setState({
      data,
    });
  }

  render() {
    let modalClass = 'modal';
    if (this.state.previewVisible) {
      modalClass += ' show d-block';
    }

    return (
      <div>
        <Row className="mb-3">
          <Col xs={8}>
            <div className="d-flex justify-content-between align-items-center">
              {this.props.isEdit && <div className="d-flex align-items-center gap-5">

                <div className="d-flex align-items-center">
                  <Form.Label className="mb-0 me-2 fw-medium">Status</Form.Label>
                  <Form.Select className="w-auto form-select--green" aria-label="Status" value={this.props.status}
                    onChange={e => {
                      this.props.setStatus(e.target.value);
                    }}>
                    <option value="enabled">Published</option>
                    <option value="disabled">Draft</option>
                  </Form.Select>
                </div>

              </div>}
              {!this.props.isEdit && !this.props.isDraftSuccess && <Button variant="link" className="font-20 fw-500" onClick={this.props.handleSaveAsDraft}><FontAwesomeIcon icon={faFloppyDisk} className="color-primary" />Save as Draft</Button>}

              <div>
              {this.props.isEdit && <Button variant="link" className="fw-medium me-3" onClick={this.props.handleSubmit}><FontAwesomeIcon icon={faFloppyDisk} className="color-primary" />Save</Button>}
                <Button variant="link" className="ms-auto fw-medium" onClick={this.showPreview.bind(this)}><FontAwesomeIcon icon={faUpDownLeftRight} className="color-primary" />Preview</Button>
              </div>
            </div>
          </Col>
          <Col xs={4} className="mt-2">
            <p className="mb-0 fw-medium text-center">Add a field</p>
          </Col>
        </Row>


        {this.state.previewVisible &&
          <div className={modalClass}>
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body">
                  <ReactFormGenerator
                    download_path=""
                    answer_data={{}}
                    action_name="Submit"
                    form_action="/"
                    form_method="POST"
                    variables={this.props.variables}
                    hide_actions={true}
                    data={this.state.data} />
                  <Button variant="primary">Submit</Button>
                </div>
                <div className="modal-footer">
                  <Button variant="outline-primary" data-dismiss="modal" onClick={this.closePreview.bind(this)}>Close</Button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}