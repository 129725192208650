const API_BASE = process.env.REACT_APP_API_BASE_URL

const getFolders = async token => {
    const url = API_BASE + '/formbuilder/folders/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}


const getSharedFolders = async (token, userID) => {
    const url = `${API_BASE}/formbuilder/folders/?permitted_users=${userID}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    });
    return res.json();
};



const getAllFoldersbyUserID = async (token, userID) => {
    const url = API_BASE + '/formbuilder/folders/?user=' + userID;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getFoldersbyUserID = async (token, userID, page = 1, pageSize = 10, search, ordering = 'lastupdated') => {
    if (page >= 2 && search) {
        page = 1;
    }
    const url = `${API_BASE}/formbuilder/folders/?permitted_users=${userID}&page=${page}&page_size=${pageSize}&search=${search}&ordering=${ordering}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getFolderById = async (token, id) => {
    const url = API_BASE + '/formbuilder/folders/' + id;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getAllFormsByFolderId = async (token, id, page = 1, pageSize = 10, search, ordering = 'lastupdated') => {
    if (page >= 2 && search) {
        page = 1;
    }
    const url = `${API_BASE}/formbuilder/forms/?folder=${id}&page=${page}&page_size=${pageSize}&search=${search}&ordering=${ordering}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json();
}

const getFormById = async (token, id) => {
    const url = API_BASE + '/formbuilder/forms/' + id + '/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getUserInfo = async (token, id) => {
    const url = API_BASE + '/users/' + id + '/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getUsers = async (token) => {
    const url = API_BASE + '/auth/users/';
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const createUser = async (formData) => {
    const url = API_BASE + '/auth/users/';
    const res = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json", 
        },
        body: JSON.stringify(formData),
    })
    return res.json()
}

const checkEmailExist = async (email) => {
    const url = `${API_BASE}/accounts/check_email/?email=${encodeURIComponent(email)}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json", 
        },
    });
    const data = await response.json();
    return data;
    
}
// /users/resend_activation/
// {
//     "email": <user email>
// }
const activateUser = async (uid, token) => {
    const url = `${API_BASE}/auth/users/activation/`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json", 
        },
        body: JSON.stringify({uid, token}),
    })
    return res.ok
}

// const createUsers = async (token, formData) => {
//     console.log(token)
//     const url = API_BASE + '/auth/users/';
//     const res = await fetch(url, {
//         method: "POST",
//         headers: {
//             Authorization: `Token ${token}`,
//             "Content-Type": "application/json",
//             Accept: "application/json",  
//         },
//         body: JSON.stringify(formData),
//     })
//     return res.json()
// }

const updateUserInfo = async (token, id, formData) => {
    const url = `${API_BASE}/users/${id}/`; // Using template literals for cleaner code
    try {
        const res = await fetch(url, {
            method: "PATCH",
            body: formData,
            headers: {
                Authorization: `Token ${token}`,
                // "Content-Type": "application/json" // Uncomment if sending JSON
            },
        });

        if (!res.ok) {
            // Handle non-2xx responses
            throw new Error(`Error: ${res.status}`);
        }

        return await res.json(); // Parsing the JSON body of the response
    } catch (error) {
        // Handle any errors from the fetch or the res.json()
        console.error("Failed to update user info:", error);
        throw error; // or return a default value or error indicator as needed
    }
}


// const updateUserInfo = async (token, id, formData) => {
//     const url = API_BASE + '/users/' + id + '/';
//     const res = await fetch(url, {
//         method: "PATCH",
//         body: formData,
//         headers: {
//             Authorization: `Token ${token}`
//         }
//     })
//     return res.json()
// }

const createForm = async (token, formData) => {
    const url = API_BASE + '/formbuilder/forms/';
    const res = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: formData,
    })
    return res.json()
}

const updateForm = async (token, id, updatedData) => {
    const url = API_BASE + '/formbuilder/forms/' + id + '/';
    const res = await fetch(url, {
        method: "PATCH",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(updatedData),
    })
    return res.json()
}

const deleteForm = async (token, id) => {
    const url = API_BASE + '/formbuilder/forms/' + id + '/';
    return await fetch(url, {
        method: "DELETE",
        headers: {
            Authorization: `Token ${token}`,
        },
        body: id + "is deleted",
    })
}


const createNewFolder = async (token, formData) => {
    const url = API_BASE + '/formbuilder/folders/';
    const res = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const updateFormField = async (token, id, data) => {
    
    const url = API_BASE + '/formbuilder/fields/' + id + '/';
    const res = await fetch(url, {
        method: "PATCH",
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify(data),
    });
    if (!id) {
        console.error("ID is undefined, cannot update form field.");
        return;
    }
    return res.json()
}
const deleteFormField = async (token, id) => {
    const url = API_BASE + '/formbuilder/fields/' + id + '/';
    return await fetch(url, {
        method: "DELETE",
        headers: {
            Authorization: `Token ${token}`
        }
    })
}

const getSubmissionsByFormId = async (token, formId, page = 1, pageSize = 10, search, ordering = 'lastupdated') => {
    const url = `${API_BASE}/formbuilder/submissions/?target_form=${formId}&page=${page}&page_size=${pageSize}&search=${search}&ordering=${ordering}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getAllSubmissionsByFormId = async (token, formId) => {
    const url = `${API_BASE}/formbuilder/submissions/?target_form=${formId}`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const getSubmissionById = async (token, id) => {
    const url = `${API_BASE}/formbuilder/submissions/${id}/`;
    const res = await fetch(url, {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`
        }
    })
    return res.json()
}

const createSubmission = async (token, data) => {
    const url = `${API_BASE}/formbuilder/submissions/`;
    const res = await fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Token ${token}`,
        },
        body: data,
    })
    return res.json()
}

const updateSubmission = async (token, id, data) => {
    const url = `${API_BASE}/formbuilder/submissions/${id}/`;
    const res = await fetch(url, {
        method: "PATCH",
        headers: {
            Authorization: `Token ${token}`,
        },
        body: data,
    })
    return res.json()
}


const resetPassword = async ( email ) => {
    const url = `${API_BASE}/auth/users/reset_password/`;
    const res = await fetch( url, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ email })
    });

    return res.ok;
  }

  const resetPasswordConfirm = async ( uid, token, newPassword) => {
    const url = `${API_BASE}/auth/users/reset_password_confirm/`;
    const res = await fetch( url, {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ uid, token, new_password: newPassword })
    });
    if (!res.ok) {
        const errorData = await res.json(); 
        throw new Error(errorData.message || "Failed to reset password");
    }

    return res.ok;
  }

  const changePassword = async ( token, currentPassword, newPassword) => {
    const url = `${API_BASE}/auth/users/set_password/`;
    const res = await fetch( url, {
        method: 'POST',
        headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        body: JSON.stringify({ current_password: currentPassword, new_password: newPassword })
    });
    if (!res.ok) {
        const errorData = await res.json(); 
        throw new Error(errorData.message || "Failed to reset password");
    }

    return res.json;
  }
const deleteSubmission = async (token, id) => {
    const url = `${API_BASE}/formbuilder/submissions/${id}/`;
    return await fetch(url, {
        method: "DELETE",
        headers: {
            Authorization: `Token ${token}`,
        },
        body: id + "is deleted",
    })
}
const deleteAllSubmissions = async (token, formId) => {
    const url = `${API_BASE}/formbuilder/batch-delete-submissions/${formId}/`;
    return await fetch(url, {
        method: "DELETE",
        headers: {
            Authorization: `Token ${token}`,
        }
    })
}

const deleteFolder = async (token,folderId) =>{
    const url = `${API_BASE}/formbuilder/batch-delete-folders/`;
    return await fetch(url, {
        method: "DELETE",
        headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: [folderId] })
    })
}

const permissionAdmin = async (token, folderId, userIds) => {
    const url = `${API_BASE}/formbuilder/folders/${folderId}/`; 
    try {
        const response = await fetch(url, {
            method: "PATCH", 
            headers: {
                Authorization: `Token ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ permitted_users: userIds})
        });
        if (!response.ok) {
            
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json(); 
    } catch (error) {
        console.error('Error updating folder permissions:', error);
       
    }
}

const DataServices = {
    getFolders,
    getSharedFolders,
    getAllFoldersbyUserID,
    getFoldersbyUserID,
    getFolderById,
    getAllFormsByFolderId,
    getFormById,
    getUserInfo,
    getUsers,
    createUser,
    checkEmailExist,
    activateUser,
    updateUserInfo,
    createForm,
    updateForm,
    deleteForm,
    createNewFolder,
    updateFormField,
    deleteFormField,
    getSubmissionsByFormId,
    getAllSubmissionsByFormId,
    getSubmissionById,
    updateSubmission,
    createSubmission,
    resetPassword,
    resetPasswordConfirm,
    changePassword,
    deleteSubmission,
    deleteAllSubmissions,
    deleteFolder,
    permissionAdmin,
}

export default DataServices;