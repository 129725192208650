import { useContext, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Dropdown from 'react-bootstrap/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { Button } from "react-bootstrap";

import Header from './header';
import { useAuth } from "../context/AuthContext";

const Layout = ({ children, title, description, isNavOpen, setIsNavOpen }) => {
    const { logout, user } = useAuth();
    const capitalize = (str) => {
        return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    }

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    {title
                        ? title
                        : "BCHU Form Builder"}
                </title>
                <meta
                    name="description"
                    key="description"
                    content={
                        description
                            ? description
                            : "This is a Template using reactjs."
                    }
                />
                <meta
                    property="og:title"
                    content={
                        title
                            ? title
                            : "BCHU Form Builder"
                    }
                    key="og:title"
                />
                <meta
                    property="og:description"
                    content={
                        description
                            ? description
                            : "This is a Template using reactjs."
                    }
                    key="og:description"
                />
                <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css" />
            </Helmet>
            <Header isNavOpen={isNavOpen} toggleNav={toggleNav} />
            <main className={`main ${isNavOpen ? 'sidenav-open' : ''}`}>
                <div className="d-flex justify-content-between mb-3">
                    <Button variant="link" className="nav-toggler" aria-label="toggle navigation" onClick={toggleNav}><FontAwesomeIcon icon={faArrowRightArrowLeft} size="1x" /></Button>
                    <Dropdown>
                        <Dropdown.Toggle className="dropdown-user">
                            <div className="dropdown-user__image"><FontAwesomeIcon icon={faUser} /></div>
                            <div className="dropdown-user__info">
                                <div className="dropdown-user__name">{capitalize(user.full_name)}</div>
                                <div className="dropdown-user__position">{capitalize(user.role)}</div>
                            </div>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                {children}
            </main>
        </HelmetProvider>
    );
};

export default Layout;