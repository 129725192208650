// import { useState, useEffect } from "react";
import { ReactFormBuilder, ElementStore } from 'react-form-builder2';
import DemoBar from './demobar';
import { toolbarItems } from './toolbarItems';

const FormBuild = (props) => {
    const { formData, setFormData, handleSaveAsDraft, isDraftSuccess } = props;
    // const [fieldList, setFieldList] = useState(formData.form_fields)
    // useEffect(()=> {
    //     ElementStore.subscribe(state => onChange(state.data))
    // },[])

    // const onChange = data => {
    //     // console.log(data)
    //     setFormData({
    //         ...formData,
    //         form_fields: data,
    //     });
    //     console.log(data)
    // }
    ElementStore.subscribe((state) => {
        const newFormFields = [];
        {state.data.map((fields) => ( 
            newFormFields.push({
                "data": JSON.stringify(fields, undefined, 1)
            })
        ))}
        // this.GetFilteredData();
        setFormData({
            ...formData,
            form_fields: newFormFields,
        });
    })
    
    return (
        <>
            <DemoBar isEdit={false} handleSaveAsDraft={handleSaveAsDraft} isDraftSuccess={isDraftSuccess} />
            <ReactFormBuilder toolbarItems={toolbarItems} />
        </>
    );
}

export default FormBuild;