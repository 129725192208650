import Layout from "../components/layout";


const Page404 = (props) => {
    const {isNavOpen, setIsNavOpen} = props;

    return(
        <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
            <div className="main__wrapper text-center d-flex flex-column justify-content-center align-items-center">
                <h2>404</h2>
                <h4>Page not found.</h4>
            </div>
        </Layout>
    )
}

export default Page404;