// Correct import for a default export
export const toolbarItems = [
  {
    key: 'Header',
  }, {
    key: 'Label',
  }, {
    key: 'TextInput',
  }, {
    key: 'EmailInput',
  }, {
    key: 'NumberInput',
  }, {
    key: 'PhoneNumber',
  }, {
    key: 'TextArea',
  }, {
    key: 'RadioButtons',
  }, {
    key: 'Checkboxes',
  }, {
    key: 'Image',
  }, {
    key: 'LineBreak',
  }, {
    key: 'Dropdown',
  }, {
    key: 'Rating',
  }, {
    key: 'DatePicker',
  }, {
    key: 'Range',
  }, {
    key: 'HyperLink',
  },
  {
    key: 'FileUpload',
  },
  {
    key: 'TwoColumnRow',
  }, {
    key: 'ThreeColumnRow',
  }, {
    key: 'FourColumnRow',
    element: 'MultiColumnRow',
  }, {
    key: 'FiveColumnRow',
    element: 'MultiColumnRow',
  }, {
    key: 'SixColumnRow',
    element: 'MultiColumnRow',
  }
];
