import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Button } from 'react-bootstrap';
import DataServices from '../api/services';
import Logo from '../logo.svg';

const WelcomePage = () => {
    const { uid, token } = useParams();
    DataServices.activateUser(uid, token);

    return (
        <Container className="d-flex align-items-center justify-content-center create-account-container">
            <div className="w-100 create-account-form">
                <Row className="justify-content-md-center">
                    <Col md={12} className="text-center">
                        <img src={Logo} className="mb-4 login__logo" alt="Logo" width="250px" />
                        <p className='mt-4'>Your account is now activated.</p>
                        <p className='mb-4'>Click the login link below to start using the Form Builder app.</p>
                        <Button href="/login" variant="primary">Login here</Button>
                    </Col>
                </Row>
            </div>
        </Container>
    );
};

export default WelcomePage;