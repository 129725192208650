import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import DataServices from '../api/services';
import Logo from '../logo.svg';

const RegistrationForm = () => {
    const { authTokens, user, setHasCompletedRegistration } = useAuth();
    const navigate = useNavigate();
    const [showContactFields, setShowContactFields] = useState(false);
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        first_name: '',
        last_name: '',
        staff: {
            phone_no: 'N/A',
            address: 'N/A',
            ip_address: '',
            role: 'Staff'
        }
    });

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'staff.role') {
            return;
        }
        if (name.includes('.')) {
            const [key, nestedKey] = name.split('.');
            setFormData(prevFormData => ({
                ...prevFormData,
                [key]: {
                    ...prevFormData[key],
                    [nestedKey]: nestedKey === 'role' ? 'Staff' : value
                }
            }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const fd = new FormData(event.currentTarget);
            const response = await DataServices.updateUserInfo(authTokens, user.id, fd);
            console.log(response)
            if (response) {
                window.location.href = '/';
            } else {
            }
        } catch (error) {
            console.error('Error updating user information:', error);

        }
    };

    return (
        <Container className="d-flex align-items-center justify-content-center create-account-container" style={{ minHeight: "100vh" }}>
            <Row className="justify-content-center">
                <Col md={8} className="w-100 text-md-start text-center">
                    <img src={Logo} className="mb-4 login__logo" alt="Logo" width="250px" />
                    <h2 className="mt-4">Welcome to BCHU</h2>
                    <p className="mb-4">Please complete your registration to get started.</p>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="first_name"
                                value={formData.first_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-5">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="last_name"
                                value={formData.last_name}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        {showContactFields && (
                            <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="staff.phone_no"
                                        value={formData.staff.phone_no}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="staff.address"
                                        value={formData.staff.address}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>
                            </>
                        )}
                        <Button variant="primary" type="submit">Complete registration</Button>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
};

export default RegistrationForm;