import { useState } from "react";
import { useNavigate, Link } from 'react-router-dom'
import { useAuth } from "../context/AuthContext";

import Logo from '../logo.svg';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


export default function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { loading, isAuthenticated, login } = useAuth();

    const handleSubmit = async event => {
        event.preventDefault()
        setErrorMessage("")
        try {
            const resp = await login(email, password)
            if (resp) {
            } else {
                setErrorMessage("Invalid login credentials");
            }
        } catch (error) {
            console.error(error)
            // TODO: actually parse api 400 error messages
            setErrorMessage(error.message)
        }
    }

    if (!loading && isAuthenticated) navigate("/")

    return (
        <div className="login">
            <div className="login__wrapper">
                <Row className="justify-content-center">
                    <Col md={7} lg={5} xl={4}>
                        <img src={Logo} className="d-block mb-5 login__logo" alt="BCHU Logo" width="250px" />
                        <h3 className="mb-3">Login</h3>
                        <p className="mb-4">Please enter your credentials to continue.</p>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" name="email" value={email} onChange={e => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" name="password" value={password} onChange={e => setPassword(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-4">
                                <Row>
                                    <Col className="text-end">
                                        <Link to="/create-account" className="text-black text-small fw-normal">Create Account</Link>
                                    </Col>
                                    <Col className="text-start">
                                        <Link to="/forgot-password" className="text-black text-small fw-normal">Forgot Password?</Link>
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                <Button variant="primary" type="submit">Login</Button>
                            </Form.Group>
                            {errorMessage ? (
                                <div className="mt-3">
                                    <p className="text-red">{errorMessage}</p>
                                </div>
                            ) : null}
                        </Form>
                    </Col>
                </Row>
            </div>
        </div>
    );
}