import { useEffect, useState, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import DataServices from '../api/services';
import { useParams, useNavigate } from 'react-router-dom';

import Layout from '../components/layout';
import PaginationComponent from "../components/pagination";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines, faFolderClosed, faArrowLeftLong, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

const Folder = (props) => {
    let params = useParams();
    const navigate = useNavigate();
    const { authTokens } = useAuth();
    const folderId = params.id;
    const pageTopRef = useRef(null);
    const {isNavOpen, setIsNavOpen} = props;

    const [folderName, setFolderName] = useState();
    const [forms, setForms] = useState([]);
    const [showDeleteFormPopUp, setShowDeleteFormPopUp] = useState(false);
    const [formNameToDelete, setFormNameToDelete] = useState("");
    const [formIdToDelete, setFormIdToDelete] = useState("");
    const [sortOption, setSortOption] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalItems, setTotalItems] = useState(0);

    useEffect(() => {
        // Function to fetch data
        const fetchData = async () => {
            try {
              const res = await DataServices.getAllFormsByFolderId(authTokens, folderId, page, pageSize, searchQuery, sortOptionMap[sortOption]);
              if (res.count > 0) {
                setTotalItems(res.count);
                setForms(res.results);
              }
            } catch (err) {
              console.log(err);
            }
          };

        if (authTokens && params.id) {
            if (folderId) {
                DataServices.getFolderById(authTokens, folderId).then(res => {
                    if (res) {
                        setFolderName(res.name);
                    }
                })
                .catch(err => console.log(err));

                fetchData();
            }
        }
    }, [authTokens, folderId, page, pageSize, searchQuery, sortOption, params]); // Include sortOption as a dependency
    
    // Map frontend sort options to backend query parameters
    const sortOptionMap = {
        '1': 'lastupdated', // Change to your actual backend query parameter for sorting by last updated
        '2': 'created', // Change to your actual backend query parameter for sorting by date created
        '3': 'name',        // Change to your actual backend query parameter for sorting by name
    };

    const handleSortChange = (event) => {
        setSortOption(event.target.value);
        setPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value); // Update the search query state
    };

    // Pagination Handlers
    const handlePageSizeChange = (newPageSize) => {
        setPageSize(newPageSize);
        setPage(1);
    }

    const handleDeleteForm = (formName, formId) => {
        setShowDeleteFormPopUp(true);
        setFormIdToDelete(formId);
        setFormNameToDelete(formName);
    }

    const closeDeleteFormPopUp = (event) => {
        setShowDeleteFormPopUp(false);
    }

    const handleDeleteButton = async (formId) => {
        console.log('formID', formId);
        if (formId) {
            await DataServices.deleteForm(authTokens, formId).then(res => {
                if (res) {
                    closeDeleteFormPopUp();
                    setForms(forms.filter(form => form.id !== formId));
                    // window.location.reload();
                    // navigate('/folder/' + folderId);

                }
            }).catch(err => console.log(err));
        }
    }

    return (
        <>
            <Layout isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen}>
                <div className="main__wrapper" ref={pageTopRef}>
                    <div className="mb-4">
                        <Link onClick={() => navigate(-1)} className="back-button"><FontAwesomeIcon icon={faArrowLeftLong} /> Back</Link>
                    </div>
                    <Row className="align-items-center justify-content-md-between">
                        <Col md className="mb-3 mb-md-0">
                            <h5 className="mb-0"><FontAwesomeIcon icon={faFolderClosed} className="mb-0 me-2 h3" /> {folderName}</h5>
                        </Col>
                        <Col md="auto">
                            <Link to={'/create?folder=' + folderId}><Button variant="primary"><FontAwesomeIcon icon={faCirclePlus} /> Create Form</Button></Link>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row className="justify-content-md-between">
                        <Col md={6} xl={5} className="mb-4">
                            <Form.Control type="search" name="search" value={searchQuery} placeholder="Search form name" onChange={handleSearchChange}></Form.Control>
                        </Col>
                        <Col md={6} className="mb-4">
                            <div className="d-flex justify-content-md-end align-items-center gap-3">
                                <span className="col-auto text-small">Sort by</span>
                                <Form.Select className="w-auto select--sort form-select--green" value={sortOption} onChange={handleSortChange}>
                                    {/* <option value="">Select sort...</option> */}
                                    <option value="1">Last Updated</option>
                                    <option value="2">Date Created</option>
                                    <option value="3">Name</option>
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                    <div className="bchu-list bchu-list--forms">
                        {
                            forms && forms.length > 0 ? (
                                forms
                                    .filter(form => form.name.toLowerCase().includes(searchQuery.toLowerCase()))
                                    .map((form, i) => (
                                        <div key={i} className="bchu-list-item">
                                            <div className="bchu-list-item__icon"><FontAwesomeIcon icon={faFileLines} /></div>
                                            <div className="bchu-list-item__info">
                                                <h5>{form.name}</h5>
                                                <div className="bchu-list-item__info-meta"><span className={form.status === 'enabled' ? 'bchu-status active' : 'bchu-status'}></span> {form.status === 'enabled' ? 'Active' : 'Draft'}</div>
                                            </div>
                                            <div className="bchu-list-item__actions">
                                                <Link to={"/edit-form/" + form.id}>Edit</Link>
                                                <Link to={"/submissions/" + form.id}>Submissions</Link>
                                                <NavDropdown title="More">
                                                    <NavDropdown.Item onClick={() => handleDeleteForm(form.name, form.id)}>Delete</NavDropdown.Item>
                                                </NavDropdown>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <div className="no-forms-message my-3">No form entries.</div>
                            )
                        }

                    </div>
                    <hr></hr>
                    <Row>
                        <Col md={6}>
                            <div className="page-size-selection mb-3 d-flex align-items-center gap-2">
                            <label htmlFor="pageSize">Items per page: </label>
                            <select
                                className="form-control w-auto py-2"
                                name="pageSize"
                                id="pageSize"
                                value={pageSize}
                                onChange={(e) => handlePageSizeChange(parseInt(e.target.value))}
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                            </select>
                            </div>
                        </Col>
                        <Col md={6}>
                            {totalItems > pageSize &&
                            <PaginationComponent
                                itemsCount={totalItems}
                                itemsPerPage={pageSize}
                                currentPage={page}
                                setPage={setPage}
                                pageTopRef={pageTopRef}
                            />
                            }
                        </Col>
                    </Row>
                </div>
            </Layout>

            {showDeleteFormPopUp &&
                <Modal
                    show={showDeleteFormPopUp}
                    onHide={closeDeleteFormPopUp}
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Body className="p-4">
                        <h6 className="mb-4">Are you sure you want to delete "{formNameToDelete}" form?</h6>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="outline-primary" onClick={closeDeleteFormPopUp}>Cancel</Button>
                        <Button variant="primary" onClick={() => handleDeleteButton(formIdToDelete)}>Delete</Button>
                    </Modal.Footer>
                </Modal>
            }

        </>
    );
}

export default Folder;